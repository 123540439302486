import React from 'react'
import Dashbaord from '../Dashboard/Dashboard'

function ClassRoomTools() {
  return (
        <>
            {/* <Dashbaord>
            <div className="min-vh-100" style={{ backgroundColor: "#f0f8ff" }}>
                <div>
                    <h4 className=" text-white fw-bold py-2 px-3" style={{backgroundColor: '#036CFF'}}>Communication Tools</h4>
                </div>
            </div>
            </Dashbaord> */}
        </>
  )
}

export default ClassRoomTools