import React from 'react'

function NoPageFind() {
  return (
    <>
        <h1 style={{paddingLeft: '50px', paddingTop: '50px', color: 'red'}}>No Page Find</h1>
    </>
  )
}

export default NoPageFind