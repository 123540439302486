import React from 'react';
import './Footer.css';


function TermOfUse() {
  return (
    <> 
      <div className="container">
        <div className="row">
          <div className="col-lg-12 py-5">
          <h3>Terms and Conditions</h3>
          <p>By signing up to AITeachingCopilot you agree to the following Terms and Conditions. Please read these Terms and Conditions ("Terms", "Terms and Conditions") carefully before using the <a href="https://www.teachingCopilot.com" target="_blank">https://www.teachingCopilot.com</a> website (the "Service") operated by AITeachingCopilot ("us", "we", or "our").</p>
          <p>Your access to and use of the service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users, and others who access or use the Service. By accessing or using the service, you agree to be bound by these Terms. If you disagree with any part of the Terms, then you may not access the service.</p>
          <h3>Subscription, Fees, and Usage</h3>
          <p>By subscribing to the service, you agree to pay the monthly or annual fee as stated on our website. This fee grants you access to the services for the duration of the subscription. Our aim is not to put a restriction on the number of times individual users use the service; however, this is based on users adopting 'fair use' behavior. We reserve the right to immediately terminate any account which uses the tools in a manner we consider to be excessive. Likewise, we reserve the right to immediately terminate any account without notice if activity on the account gives us reason to believe multiple persons are using a single account.</p>
          <h3>Accounts</h3>
          <p>When you create an account with us, you must provide accurate and complete information. You are responsible for safeguarding the password that you use to access the service and for any activities or actions under your password, whether your password is with our service or a third-party service. You agree not to disclose your password to any third party. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account. Sharing account credentials is strictly prohibited. Accounts found to be in violation of this rule will be subject to immediate suspension or termination.</p>
          <h3>AI Accuracy and Limitations</h3>
          <p>While we strive to provide accurate and helpful information through the use of OpenAI models, we don't guarantee the accuracy, completeness, or usefulness of any information generated by the AI. You acknowledge that the AI-generated content may contain errors, omissions, or inaccuracies, and you agree to use the service at your own risk. We are not responsible for any decisions or actions taken based on the AI-generated content, and we disclaim any liability for damages or losses resulting from your reliance on the AI-generated content.</p>
          <h3>Intellectual Property</h3>
          <p>The service and its original content, features, and functionality are and will remain the exclusive property of AITeachingCopilot and its licensors. The Service is protected by copyright, trademark, and other laws of both the United Kingdom and foreign countries. Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of AITeachingCopilot.</p>
          <h3>Termination</h3>
          <p>We may terminate or suspend access to our service immediately, without prior notice or liability, for any reason whatsoever, including, without limitation, if you breach the terms. All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity, and limitations of liability.</p>
          <h3>Changes</h3>
          <p>We reserve the right, at our sole discretion, to modify or replace these terms at any time. If a revision is material, we will try to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion. By continuing to access or use our Service after these revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, please stop using the service. Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms shall remain in effect.</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default TermOfUse